import { useState, createContext, useEffect, useCallback } from 'react';
// Types
import { GetMerchCartItemsList_getMerchCartItemsList } from 'api/merch/types/GetMerchCartItemsList';
import { GetMemorabiliaBySlug_getMemorabiliaBySlug } from 'api/memorabilia/types/GetMemorabiliaBySlug';
import { UserRole } from 'api/graphql-global-types';
import {
  GetStoreMerchProduct_getStoreMerchProduct,
  GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants,
} from 'api/merch/types/GetStoreMerchProduct';
// Helpers
import {
  addMerchShoppingCartToLocalStorage,
  getMerchShoppingCartFromLocalStorage,
  clearMerchShoppingCartFromLocalStorage,
} from 'helpers/storage';
import {
  setTokenToCookies,
  getTokenFromCookies,
  clearTokenFromCookies,
  setGuestTokenToCookies,
  getGuestTokenFromCookies,
  clearGuestTokenFromCookies,
} from 'helpers/cookies';

export type MerchShoppingCartItem =
  | {
      variantId?: number;
      amount: number;
      merchProductVariant?: GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants & {
        merchProduct?: GetStoreMerchProduct_getStoreMerchProduct;
      };
      memorabilia?: GetMemorabiliaBySlug_getMemorabiliaBySlug;
      memorabiliaId?: string;
    }
  | GetMerchCartItemsList_getMerchCartItemsList;

export type MerchShoppingCart = MerchShoppingCartItem[];

export type Token = {
  id: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  role: UserRole | null;
};

type AppContextState = {
  token: Token | null;
  guestToken: Token | null;
  showLoginModal: boolean;
  showSignUpModal: boolean;
  showSignUpV2Modal: boolean;
  showUserScheduleInterviewModal: boolean;
  showNonRegisteredUserScheduleInterviewModal: boolean;
  showQuickSignUpProfileSetupModal: boolean;
  showAthleteSignUpModal: boolean;
  showAthleteSignUpEmailModal: boolean;
  showAthleteSignUpSocialModal: boolean;
  showOrganizationSignUpModal: boolean;
  showContentCreatorSignUpModal: boolean;
  showOrganizationSignUpEmailModal: boolean;
  showContentCreatorSignUpEmailModal: boolean;
  showOrganizationSignUpSocialModal: boolean;
  showContentCreatorSignUpSocialModal: boolean;
  showChooseSignUpPathModal: boolean;
  showChooseSignUpPathModalV2: boolean;
  isGoogleSdkFailed: boolean;
  merchShoppingCart: MerchShoppingCart;
  withOnboardingWatcher: boolean;
  withParticipantFlow: boolean;
  participantStreamId: string;
};

type AppContextHandlers = {
  setToken: (token: Token) => void;
  clearToken: () => void;
  setGuestToken: (token: Token) => void;
  clearGuestToken: () => void;
  setAppContext: React.Dispatch<React.SetStateAction<AppContextState>>;
  setLoginModalVisibility: (visibility: boolean) => void;
  setSignUpModalVisibility: (visibility: boolean) => void;
  setSignUpV2ModalVisibility: (visibility: boolean) => void;
  setUserScheduleInterviewModalVisibility: (visibility: boolean) => void;
  setNonRegisteredUserScheduleInterviewModalVisibility: (
    visibility: boolean
  ) => void;
  setQuickSignUpProfileSetupModalVisibility: (visibility: boolean) => void;
  setChooseSignUpPathModalVisibility: (visibility: boolean) => void;
  setChooseSignUpPathModalVisibilityV2: (visibility: boolean) => void;
  setAthleteSignUpModalVisibility: (visibility: boolean) => void;
  setAthleteSignUpEmailModalVisibility: (visibility: boolean) => void;
  setAthleteSignUpSocialModalVisibility: (visibility: boolean) => void;
  setContentCreatorSignUpModalVisibility: (visibility: boolean) => void;
  setOrganizationSignUpModalVisibility: (visibility: boolean) => void;
  setOrganizationSignUpEmailModalVisibility: (visibility: boolean) => void;
  setContentCreatorSignUpEmailModalVisibility: (visibility: boolean) => void;
  setOrganizationSignUpSocialModalVisibility: (visibility: boolean) => void;
  setContentCreatorSignUpSocialModalVisibility: (visibility: boolean) => void;
  setGoogleSkdFailedStatus: (status: boolean) => void;
  addMerchItemToShoppingCart: (
    item: MerchShoppingCartItem,
    prevVariantId?: number
  ) => void;
  removeMerchItemFromShoppingCart: (variantId: number) => void;
  removeMemorabiliaItemFromShoppingCart: (memorabiliaId: string) => void;
  clearMerchItemsFromShoppingCart: () => void;
  setWithOnboardingWatcher: (status: boolean) => void;
  setWithParticipantFlow: (status: boolean) => void;
  setParticipantStreamId: (streamId: string) => void;
};

export type AppContextType = AppContextHandlers & AppContextState;

const initialState = {
  token: getTokenFromCookies(),
  guestToken: getGuestTokenFromCookies(),
  showLoginModal: false,
  showSignUpModal: false,
  showSignUpV2Modal: false,
  showAthleteSignUpModal: false,
  showUserScheduleInterviewModal: false,
  showNonRegisteredUserScheduleInterviewModal: false,
  showQuickSignUpProfileSetupModal: false,
  showAthleteSignUpEmailModal: false,
  showAthleteSignUpSocialModal: false,
  showOrganizationSignUpModal: false,
  showContentCreatorSignUpModal: false,
  showOrganizationSignUpEmailModal: false,
  showContentCreatorSignUpEmailModal: false,
  showOrganizationSignUpSocialModal: false,
  showContentCreatorSignUpSocialModal: false,
  showChooseSignUpPathModal: false,
  showChooseSignUpPathModalV2: false,
  isGoogleSdkFailed: false,
  merchShoppingCart: getMerchShoppingCartFromLocalStorage(),
  withOnboardingWatcher: true,
  withParticipantFlow: false,
  participantStreamId: '',
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppProvider: React.FC = ({ children }) => {
  const [appContext, setAppContext] = useState<AppContextState>(initialState);

  useEffect(() => {
    const token = getTokenFromCookies();

    if (token?.accessToken && token?.refreshToken && !appContext.token) {
      setAppContext((state) => ({
        ...state,
        token,
      }));
    }
  }, [appContext.token]);

  const setToken = (token: Token) => {
    clearGuestToken();
    setTokenToCookies(token);
    setAppContext((state) => ({
      ...state,
      token,
    }));
  };

  const clearToken = () => {
    clearTokenFromCookies();
    setAppContext((state) => ({
      ...state,
      token: null,
    }));
  };

  const setGuestToken = (guestToken: Token) => {
    setGuestTokenToCookies(guestToken);
    setAppContext((state) => ({
      ...state,
      guestToken,
    }));
  };

  const clearGuestToken = () => {
    clearGuestTokenFromCookies();
    setAppContext((state) => ({
      ...state,
      guestToken: null,
    }));
  };

  const addMerchItemToShoppingCart = (
    cartItem: MerchShoppingCartItem | MerchShoppingCart,
    prevVariantId?: number
  ) => {
    if (Array.isArray(cartItem)) {
      setAppContext((state) => {
        return {
          ...state,
          merchShoppingCart: cartItem,
        };
      });
      addMerchShoppingCartToLocalStorage(cartItem);
    } else {
      const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();

      const isMerchAlreadyExists = existingMerchShoppingCart.some((item) => {
        if (item.variantId && cartItem.variantId) {
          return item.variantId === cartItem.variantId;
        }
      });

      const isMemorabiliaAlreadyInCart = existingMerchShoppingCart.some(
        (item) => {
          if (item.memorabiliaId && cartItem.memorabiliaId) {
            return item.memorabiliaId === cartItem.memorabiliaId;
          }
        }
      );

      const updatedShoppingCart =
        isMerchAlreadyExists || isMemorabiliaAlreadyInCart
          ? existingMerchShoppingCart.map((item) => {
              if (
                item.variantId &&
                cartItem.variantId &&
                item.variantId === cartItem.variantId
              ) {
                return { ...cartItem };
              }

              if (
                item.memorabiliaId &&
                cartItem.memorabiliaId &&
                item.memorabiliaId === cartItem.memorabiliaId
              ) {
                return { ...cartItem };
              }

              return item;
            })
          : [cartItem, ...existingMerchShoppingCart];

      const updatedCartWithoutPrevVariant = prevVariantId
        ? updatedShoppingCart.filter((item) => item.variantId !== prevVariantId)
        : updatedShoppingCart;

      setAppContext((state) => {
        return {
          ...state,
          merchShoppingCart: updatedCartWithoutPrevVariant,
        };
      });
      addMerchShoppingCartToLocalStorage(updatedCartWithoutPrevVariant);
    }
  };

  const removeMerchItemFromShoppingCart = (variantId: number) => {
    const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();
    const updatedShoppingCart = existingMerchShoppingCart.filter(
      (item) => item.variantId !== variantId
    );

    setAppContext((state) => {
      return {
        ...state,
        merchShoppingCart: updatedShoppingCart,
      };
    });
    addMerchShoppingCartToLocalStorage(updatedShoppingCart);
  };

  const removeMemorabiliaItemFromShoppingCart = (memorabiliaId: string) => {
    const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();
    const updatedShoppingCart = existingMerchShoppingCart.filter(
      (item) => item.memorabiliaId !== memorabiliaId
    );

    setAppContext((state) => {
      return {
        ...state,
        merchShoppingCart: updatedShoppingCart,
      };
    });
    addMerchShoppingCartToLocalStorage(updatedShoppingCart);
  };

  const clearMerchItemsFromShoppingCart = useCallback(() => {
    setAppContext((state) => ({
      ...state,
      merchShoppingCart: [],
    }));
    clearMerchShoppingCartFromLocalStorage();
  }, []);

  const setLoginModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showLoginModal: visibility,
    }));
  }, []);

  const setSignUpModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showSignUpModal: visibility,
    }));
  }, []);

  const setSignUpV2ModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showSignUpV2Modal: visibility,
    }));
  }, []);

  const setUserScheduleInterviewModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showUserScheduleInterviewModal: visibility,
      }));
    },
    []
  );

  const setNonRegisteredUserScheduleInterviewModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showNonRegisteredUserScheduleInterviewModal: visibility,
      }));
    },
    []
  );

  const setQuickSignUpProfileSetupModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showQuickSignUpProfileSetupModal: visibility,
      }));
    },
    []
  );

  const setAthleteSignUpModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showAthleteSignUpModal: visibility,
    }));
  }, []);

  const setAthleteSignUpEmailModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showAthleteSignUpEmailModal: visibility,
      }));
    },
    []
  );

  const setAthleteSignUpSocialModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showAthleteSignUpSocialModal: visibility,
      }));
    },
    []
  );

  const setOrganizationSignUpEmailModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showOrganizationSignUpEmailModal: visibility,
      }));
    },
    []
  );
  const setContentCreatorSignUpEmailModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showContentCreatorSignUpEmailModal: visibility,
      }));
    },
    []
  );

  const setOrganizationSignUpSocialModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showOrganizationSignUpSocialModal: visibility,
      }));
    },
    []
  );

  const setContentCreatorSignUpSocialModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showContentCreatorSignUpSocialModal: visibility,
      }));
    },
    []
  );

  const setOrganizationSignUpModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showOrganizationSignUpModal: visibility,
      }));
    },
    []
  );

  const setContentCreatorSignUpModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showContentCreatorSignUpModal: visibility,
      }));
    },
    []
  );

  const setChooseSignUpPathModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showChooseSignUpPathModal: visibility,
      }));
    },
    []
  );

  const setChooseSignUpPathModalVisibilityV2 = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showChooseSignUpPathModalV2: visibility,
      }));
    },
    []
  );

  const setGoogleSkdFailedStatus = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      isGoogleSdkFailed: status,
    }));
  }, []);

  const setWithOnboardingWatcher = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      withOnboardingWatcher: status,
    }));
  }, []);

  const setWithParticipantFlow = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      withParticipantFlow: status,
    }));
  }, []);

  const setParticipantStreamId = useCallback((streamId: string) => {
    setAppContext((state) => ({
      ...state,
      participantStreamId: streamId,
    }));
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...appContext,
        setToken,
        clearToken,
        setGuestToken,
        clearGuestToken,
        setAppContext,
        setLoginModalVisibility,
        setSignUpModalVisibility,
        setSignUpV2ModalVisibility,
        setUserScheduleInterviewModalVisibility,
        setNonRegisteredUserScheduleInterviewModalVisibility,
        setQuickSignUpProfileSetupModalVisibility,
        setAthleteSignUpModalVisibility,
        setAthleteSignUpEmailModalVisibility,
        setAthleteSignUpSocialModalVisibility,
        setContentCreatorSignUpModalVisibility,
        setOrganizationSignUpModalVisibility,
        setChooseSignUpPathModalVisibility,
        setChooseSignUpPathModalVisibilityV2,
        setGoogleSkdFailedStatus,
        addMerchItemToShoppingCart,
        removeMerchItemFromShoppingCart,
        removeMemorabiliaItemFromShoppingCart,
        clearMerchItemsFromShoppingCart,
        setWithOnboardingWatcher,
        setWithParticipantFlow,
        setParticipantStreamId,
        setOrganizationSignUpEmailModalVisibility,
        setContentCreatorSignUpEmailModalVisibility,
        setOrganizationSignUpSocialModalVisibility,
        setContentCreatorSignUpSocialModalVisibility,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
